import React from 'react';
import './CameraUsage.css'; // Import CSS file for styling

function CameraUsage() {
  // Sample data for team logos and names
  const teams = [
    { id: 1, name: "Inter Miami CF", logoUrl: "https://assets-global.website-files.com/65a1660e61e47f70a246ac5c/65d715c2a2791856c88e3628__logoMiami(US).png" },
    { id: 2, name: "Wolverhampton", logoUrl: "https://assets-global.website-files.com/65a1660e61e47f70a246ac5c/65d71176c003e82249a38908__logoWolves.png" },
    { id: 3, name: "Lewes F.C.", logoUrl: "https://assets-global.website-files.com/65a1660e61e47f70a246ac5c/65d7117648cbee299a79a9e0__logoLewes.png" },
    { id: 4, name: "Florida Kraze Krush", logoUrl: "https://assets-global.website-files.com/65a1660e61e47f70a246ac5c/65d715c2984e5e4c56f1f016__logoFlorida-Krush(US).png" },
    { id: 5, name: "Wellington Phoenix", logoUrl: "https://assets-global.website-files.com/65a1660e61e47f70a246ac5c/65d7181804defdbfb46b9801__logoWellington-Phoenix(NZ).png" },
    { id: 6, name: "Northern NSWF", logoUrl: "https://assets-global.website-files.com/65a1660e61e47f70a246ac5c/65d718183ac3667157a34071__logoNorthern-NSW(AU).png" },
    { id: 7, name: "Sting Soccer Club", logoUrl: "https://assets-global.website-files.com/65a1660e61e47f70a246ac5c/65d715c3b53e3d93aa6f1e3c__logoSting-soccer-club(US).png" },
    { id: 8, name: "Tennessee SC", logoUrl: "https://assets-global.website-files.com/65a1660e61e47f70a246ac5c/65d715c3542c99a4b13f9d4d__logoTennessee(US).png" },
    { id: 9, name: "Arlington Soccer", logoUrl: "https://assets-global.website-files.com/65a1660e61e47f70a246ac5c/65d71a3d5b27ca4211b94c66__logoArlington(US).png" },
    { id: 10, name: "LOSC Lille", logoUrl: "https://assets-global.website-files.com/65a1660e61e47f70a246ac5c/65d715ebeb8fe7b06ad223ad__logoLOSC-Lille(FR).png" },
    { id: 11, name: "Burnley F.C.", logoUrl: "https://assets-global.website-files.com/65a1660e61e47f70a246ac5c/65d710f05c7dd42d43744683__logoBurnley.png" },
    { id: 12, name: "Phoenix Rising FC", logoUrl: "https://assets-global.website-files.com/65a1660e61e47f70a246ac5c/65d715c34f30f4ccb5919a46__logoPhoenix-Rising(US).png" }
  ];

  return (
    <div className="teams-container">
      <h2 className="heading">Our camera, Veo, is trusted and used by clubs from all over the world</h2>
      <div className="team-list">
        {teams.map((team) => (
          <div key={team.id} className="team-item">
            <div className="team-info">
              <img src={team.logoUrl} alt={team.name} className="team-logo" />
              <p className="team-name">{team.name}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default CameraUsage;
