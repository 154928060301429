import React, { useState, useEffect, useRef } from 'react';
import './VideoCarousel.css';

const videos = [
    {
        id: 1,
        src: process.env.PUBLIC_URL + '/videos/angledScreamerGoal.mp4',
        title: 'Angled Screamer'
    },
    {
        id: 2,
        src: process.env.PUBLIC_URL + '/videos/greatPlayGoal.mp4',
        title: 'Great Play'
    },
    {
        id: 3,
        src: process.env.PUBLIC_URL + '/videos/girlsScreamerGoal.mp4',
        title: 'Girls Screamer'      
    },
    {
        id: 4,
        src: process.env.PUBLIC_URL + '/videos/sombreroGoal.mp4',
        title: 'Sombrero Goal'
    },
    {
        id: 5,
        src: process.env.PUBLIC_URL + '/videos/dogBlocksGoal.mp4',
        title: 'Dog Block'
    },
    {
        id: 6,
        src: process.env.PUBLIC_URL + '/videos/blooperReelGoal.mp4',
        title: 'Blooper Reel'
     }
];

const VideoCarousel = () => {
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [progress, setProgress] = useState(Array(videos.length).fill(0));
    const videoRefs = useRef([]);

    useEffect(() => {
        const currentVideo = videoRefs.current[currentVideoIndex];

        const handleTimeUpdate = () => {
            const newProgress = [...progress];
            newProgress[currentVideoIndex] = (currentVideo.currentTime / currentVideo.duration) * 100;
            setProgress(newProgress);
        };

        const handleEnded = () => {
            const newProgress = [...progress];
            newProgress[currentVideoIndex] = 0;
            setProgress(newProgress);
            setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
            // Reset the current video to the beginning
            currentVideo.currentTime = 0;
        };

        currentVideo.addEventListener('timeupdate', handleTimeUpdate);
        currentVideo.addEventListener('ended', handleEnded);

        return () => {
            currentVideo.removeEventListener('timeupdate', handleTimeUpdate);
            currentVideo.removeEventListener('ended', handleEnded);
        };
    }, [currentVideoIndex, progress]);

    const handleProgressBarClick = (index) => {
        setProgress(Array(videos.length).fill(0));
        setCurrentVideoIndex(index);
    };

    return (
        <div className="video-carousel-container">
            {videos.map((video, index) => (
                <video
                    key={video.id}
                    ref={(el) => (videoRefs.current[index] = el)}
                    className={`video ${index === currentVideoIndex ? 'active' : ''}`}
                    src={video.src}
                    autoPlay
                    muted
                    style={{ display: index === currentVideoIndex ? 'block' : 'none' }}
                />
            ))}
            <div className="progress-container">
                {videos.map((video, index) => (
                    <div
                        key={video.id}
                        className="progress-bar"
                        onClick={() => handleProgressBarClick(index)}
                    >
                        <div
                            className="progress"
                            style={{ width: `${progress[index]}%` }}
                        ></div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default VideoCarousel;
