import React, {Component} from 'react';
import { Row, Col, FormControl, Form, Button } from 'react-bootstrap';

import { FaInstagram } from 'react-icons/fa';
import { AiOutlineFacebook } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { BsPhone } from "react-icons/bs";

import ModalPopup from "./ModalPopup";
import "./ContactUs.css"
import * as contactUsUtils from "./ContactUsUtils";
import { Axios } from "../../firebase/FirebaseConfig";


class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = contactUsUtils.getInitialState();
    }

    onPreferredContactMethodChosen = (e) => {
        this.setState({
            userEmail: '',
            userPhoneNumber: '',
            preferredContactMethod: e.target.value
        });
    }

    submitButtonClicked = () => {
        console.log(this.state);
        if(!contactUsUtils.allFieldsArePopulated(this.state)) {
            alert('Please fill out all the fields');
            return;
        }

        if(this.state.preferredContactMethod === 'email' && !contactUsUtils.emailIsValid(this.state.userEmail)) {
            alert('Invalid email');
            return;
        } else if(this.state.preferredContactMethod === 'phone' && !contactUsUtils.isValidPhoneNumber(this.state.userPhoneNumber)) {
          alert('Invalid phone number');
          return;
        }
        
        this.sendEmail();
    }

    sendEmail = () => {
        let userContactInfo = this.state.preferredContactMethod === 'email' 
            ? this.state.userEmail
            : this.state.userPhoneNumber;

        
        Axios.post('https://us-central1-ondaycapture-23d09.cloudfunctions.net/submit', 
        {
            senderContactInfo: userContactInfo,
            subject: this.state.userEmailSubject,
            message: this.state.userEmailMessage,
        })
        .then((response) => {
            this.openSuccessModal();
            this.clearEmailTextBoxes();
        }, (error) => {
            console.log(error);
            this.openFailureModal();
        });
    }

    clearEmailTextBoxes = () => {
        console.log(this.state);
        this.setState({
            userEmail: "",
            userPhoneNumber: "",
            userEmailSubject: "",
            userEmailMessage: "",
        });
        console.log(this.state);
    }

    openSuccessModal = () => {
        this.setState({showSuccessModal: true});
    }

    closeSuccessModal = () => {
        this.setState({showSuccessModal: false});
    }

    openFailureModal = () => {
        this.setState({showFailureModal: true});
    }

    closeFailureModal = () => {
        this.setState({showFailureModal: false});
    }

    
    render() {
        return (
            <div className="container-fluid grey-background-color">
                <ModalPopup
                    showModal={this.state.showSuccessModal}
                    closeModal={() => this.closeSuccessModal()}
                    modalTitle={contactUsUtils.modalPopupSuccessTitle()}
                    modalMessage={contactUsUtils.modalPopupEmailSuccessMessage()}
                />

                <ModalPopup //this is the Failure popup
                    showModal={this.state.showFailureModal}
                    closeModal={() => this.closeFailureModal()}
                    modalTitle={contactUsUtils.modalPopupFailureTitle()}
                    modalMessage={contactUsUtils.modalPopupEmailFailureMessage()}
                />

                

                <Row>
                    <Col lg={"4"} md={"4"} sm={"4"} className="contact-us-section-text-align-left">
                        <div style={{ height: '5vh' }}></div>
                        <div className="right-border">

                            <Row className="contact-info-headers">
                                <div>
                                    <div className="contact-info-label">Contact Info</div>
                                </div>
                            </Row>


                            <Row className="phone-numbers">
                                <div>
                                    <div className="contact-us-section-headers">Phone</div>
                                    <div className="padding-5-percent">
                                    <BsPhone className="phone"/>
                                        <a href="tel:832-316-4214" className="image-link-styling-and-spacing">
                                            {'832-316-4214'}
                                        </a>
                                    </div>
                                </div>
                            </Row>


                            <Row className="email">
                                <div>
                                    <div className="contact-us-section-headers">Email</div>
                                    <div id="padding-5-percent">
                                        <AiOutlineMail className="phone"/>
                                        <a href="mailto: ondaycapture@gmail.com" className="image-link-styling-and-spacing">
                                            {'ondaycapture@gmail.com'}
                                        </a>
                                    </div>
                                </div>
                            </Row>


                            <Row className="socials">
                                <div>
                                    <div className="contact-us-section-headers">Socials</div>
                                    <div id="padding-5-percent">
                                        <FaInstagram className="instagram"/> 
                                        <a href="https://www.instagram.com/ondaycapture/" className="image-link-styling-and-spacing">
                                            @OnDayCapture
                                        </a>
                                    </div>
                                    <div id="padding-5-percent">
                                        <AiOutlineFacebook className="facebook"/> 
                                        <a href="https://www.facebook.com/ondaycapture" className="image-link-styling-and-spacing">
                                            On Day Capture
                                        </a>
                                    </div>
                                </div>
                            </Row>

                            <Row className="houston">
                                <div>
                                    <div className="contact-us-section-headers">Houston, TX</div>
                                </div>
                            </Row>
                        </div>
                    </Col>


                    <Col className=''>
                    {/* <Col lg={"3"} md={"3"} sm="3" className="send-a-message-section-text-align-left"> */}
                        {/* <div style={{ height: '5vh' }}></div> */}
                        <div>
                            <div style={{ height: '5vh' }}></div>
                            <Row className="send-a-message">
                                <div>
                                    <div className="send-a-message-label">Send Us A Message</div>
                                    {/* <div className="contact-us-section-headers">Preferred Contact Method</div> */}
                                    <div className="radio-buttons">
                                        <Form>
                                            <Form.Group className="mb-3">
                                                <Form.Check
                                                    type="radio"
                                                    inline
                                                    value="email"
                                                    label="Email"
                                                    onChange={this.onPreferredContactMethodChosen}
                                                    checked={this.state.preferredContactMethod === 'email'}
                                                />
                                                <Form.Check
                                                    type="radio"
                                                    inline
                                                    label="Phone"
                                                    value="phone"
                                                    onChange={e => this.onPreferredContactMethodChosen(e)}
                                                    checked={this.state.preferredContactMethod === 'phone'}

                                                />
                                                </Form.Group>
                                                {this.state.preferredContactMethod === 'email' &&
                                                    <Form.Group className="mb-3">
                                                        <FormControl
                                                            placeholder="Email"
                                                            type="email"
                                                            value={this.state.userEmail}
                                                            onChange={e => this.setState({userEmail: e.target.value})}
                                                        />
                                                    </Form.Group>
                                                }
                                                {this.state.preferredContactMethod === 'phone' &&
                                                    <Form.Group className="mb-3">
                                                        <FormControl
                                                            placeholder="Phone"
                                                            type="phone"
                                                            inputMode="numeric"
                                                            value={this.state.userPhoneNumber}
                                                            onChange={e => this.setState({userPhoneNumber: e.target.value})}
                                                        />
                                                    </Form.Group>
                                                }
                                                <Form.Group className="email-input mb-3">
                                                    <FormControl
                                                        className="email-input"
                                                        placeholder="Subject"
                                                        aria-label="Subject"
                                                        aria-describedby="basic-addon1"
                                                        value={this.state.userEmailSubject}
                                                        onChange={e => this.setState({userEmailSubject: e.target.value})}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <FormControl 
                                                        className="message-input" 
                                                        placeholder="Message"
                                                        as="textarea" 
                                                        rows={6}
                                                        value={this.state.userEmailMessage}
                                                        onChange={e => this.setState({userEmailMessage: e.target.value})}
                                                    />
                                                </Form.Group>
                                                <Button 
                                                    variant="primary"
                                                    size="lg"
                                                    className="button" 
                                                    onClick={this.submitButtonClicked}
                                                    >SUBMIT</Button>
                                            </Form>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Col>


                    {/* <Col lg md sm="3">
                        <div>
                            <img
                            className="map-image-size"
                            src={staticMap}
                            alt={"missing"}
                            />
                        </div>
                    </Col> */}

                    
                </Row>
                <div style={{ height: '2vh' }}></div>
            </div>
   
        )
    }
}

export default ContactUs;