import React from 'react';
import './Services.css'; // Import CSS file for styling
import VideoCarousel from './servicesComponents/VideoCarousel';
import VideoCarouselMobile from './servicesComponents/VideoCarouselMobile';
import HighlightVideo from './servicesComponents/HighlightVideo';

function Services() {

const isMobileDevice = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };

  return (
    <section className="services">
      <h2>Our Services</h2>
      <div className="service">
        <h3>Record Full Games</h3>
        <p class="centered-text">We provide professional recording services for full soccer games. Never miss a moment of the action!</p>
        {isMobileDevice() ? <VideoCarouselMobile /> : <VideoCarousel />}
      </div>
      <div className="service">
        <h3>AI Generated Highlights</h3>
        <p class="centered-text">Our camera features advanced AI technology that automatically generates clips and highlights of the best moments in the game. This allows you to download individual video clips for each player or create a highlight reel with just the best moments.</p>
        <HighlightVideo />
      </div>
      <div className="service">
        <h3>Live Streaming</h3>
        <p class="centered-text">We also have the capability to live stream games for all your fans that can't make it to the game! We can stream to YouTube, Facebook, Twitch, and many other streaming services. We can also overlay the scoreboard and the time of the game. If you have sponsors you'd like to display, we can also overlay banners. Contact us for a demo!</p>
      </div>
      <div className="service">
        <h3>Additional Sports</h3>
        <p class="centered-text">While we mainly focus on soccer, our cameras also work really well with football, rugby, basketball, lacrosse, and volleyball!</p>
      </div>
    </section>
  );
}

export default Services;
