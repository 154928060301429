import React, {Component} from 'react';
import { Row, FormControl, Form, Button, Col } from 'react-bootstrap';
import "./ContactUs.css"
import { FaInstagram } from 'react-icons/fa';
import { AiOutlineFacebook } from "react-icons/ai";
import { BsPhone } from "react-icons/bs";

import * as contactUsUtils from "./ContactUsUtils";
import { Axios } from "../../firebase/FirebaseConfig";
import ModalPopup from "./ModalPopup";

class ContactUsMobile extends Component {
    constructor(props) {
        super(props);
        this.state = contactUsUtils.getInitialState();
    }

    componentDidMount() {
        
    }

    onPreferredContactMethodChosen = (e) => {
        this.setState({
            userEmail: '',
            userPhoneNumber: '',
            preferredContactMethod: e.target.value
        });
    }

    submitButtonClicked = () => {
        if(!contactUsUtils.allFieldsArePopulated(this.state)) {
            alert("Please fill out all the fields");
            return;
        }

        if(this.state.preferredContactMethod === 'email' && !contactUsUtils.emailIsValid(this.state.userEmail)) {
            alert('Invalid email');
            return;
        } else if(this.state.preferredContactMethod === 'phone' && !contactUsUtils.isValidPhoneNumber(this.state.userPhoneNumber)) {
            alert('Invalid phone number');
            return;
        }

        this.sendEmail();
    }

    sendEmail = () => {
        let userContactInfo = this.state.preferredContactMethod === 'email' 
            ? this.state.userEmail
            : this.state.userPhoneNumber;

        
        console.log(userContactInfo);
        Axios.post('https://us-central1-ondaycapture-23d09.cloudfunctions.net/submit', 
        {
            senderContactInfo: userContactInfo,
            subject: this.state.userEmailSubject,
            message: this.state.userEmailMessage,
        })
        .then((response) => {
            this.openSuccessModal();
            this.clearEmailTextBoxes();
        }, (error) => {
            console.log(error);
            this.openFailureModal();
        });
    }

    clearEmailTextBoxes = () => {
        this.setState({
            userEmail: "",
            userPhoneNumber: "",
            userEmailSubject: "",
            userEmailMessage: "",
        })
    }

    openSuccessModal = () => {
        this.setState({showSuccessModal: true});
    }

    closeSuccessModal = () => {
        this.setState({showSuccessModal: false});
    }

    openFailureModal = () => {
        this.setState({showFailureModal: true});
    }

    closeFailureModal = () => {
        this.setState({showFailureModal: false});
    }

    render() {
        return (
            <div className="container-fluid grey-background-color">
                <ModalPopup
                    showModal={this.state.showSuccessModal}
                    closeModal={() => this.closeSuccessModal()}
                    modalTitle={contactUsUtils.modalPopupSuccessTitle()}
                    modalMessage={contactUsUtils.modalPopupEmailSuccessMessage()}
                />
                <ModalPopup //this is the Failure popup
                    showModal={this.state.showFailureModal}
                    closeModal={() => this.closeFailureModal()}
                    modalTitle={contactUsUtils.modalPopupFailureTitle()}
                    modalMessage={contactUsUtils.modalPopupEmailFailureMessage()}
                />

                <Row className="contact-info-headers align-items-center">
                    <Col>
                        <div className="contact-info-label-mobile">Get In Touch With Us</div>
                        <div className="contact-info-on-day-capture-label">ondaycapture@gmail.com</div>
                    </Col>
                </Row>
                <hr/>
                <Row className="align-items-center">
                    <Col className="contact-links">
                        <div>
                            <Row className="justify-content-center socials-row">
                                <Col>
                                    <BsPhone className="phone-mobile"/>
                                    <a href="tel:832-316-4214" className="image-link-styling-and-spacing-mobile">
                                        {'832-316-4214'}
                                    </a>
                                </Col>
                            </Row>
                            <Row className="justify-content-center socials-row">
                                <Col>
                                    <FaInstagram className="instagram-mobile"/> 
                                    <a href="https://www.instagram.com/ondaycapture/" className="image-link-styling-and-spacing-mobile">
                                        {"@OnDayCapture"}
                                    </a>
                                </Col>
                            </Row>
                            <Row className="justify-content-center socials-row">
                                <Col>
                                    <AiOutlineFacebook className="facebook-mobile"/> 
                                    <a href="https://www.facebook.com/ondaycapture" className="image-link-styling-and-spacing-mobile">
                                        On Day Capture
                                    </a>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    
                </Row>
                
                <Row className="send-a-message justify-content-center">
                    <div>
                        <div className="radio-buttons">
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Check
                                        type="radio"
                                        inline
                                        value="email"
                                        label="Email"
                                        onChange={this.onPreferredContactMethodChosen}
                                        checked={this.state.preferredContactMethod === 'email'}
                                    />
                                    <Form.Check
                                        type="radio"
                                        inline
                                        label="Phone"
                                        value="phone"
                                        onChange={e => this.onPreferredContactMethodChosen(e)}
                                        checked={this.state.preferredContactMethod === 'phone'}

                                    />
                                    </Form.Group>
                                    {this.state.preferredContactMethod === 'email' &&
                                        <Form.Group className="mb-3">
                                            <FormControl
                                                placeholder="Email"
                                                type="email"
                                                value={this.state.userEmail}
                                                onChange={e => this.setState({userEmail: e.target.value})}
                                            />
                                        </Form.Group>
                                    }
                                    {this.state.preferredContactMethod === 'phone' &&
                                        <Form.Group className="mb-3">
                                            <FormControl
                                                placeholder="Phone"
                                                type="phone-mobile"
                                                inputMode="numeric"
                                                value={this.state.userPhoneNumber}
                                                onChange={e => this.setState({userPhoneNumber: e.target.value})}
                                            />
                                        </Form.Group>
                                    }
                                    <Form.Group className="email-input mb-3">
                                        <FormControl
                                            className="email-input"
                                            placeholder="Subject"
                                            aria-label="Subject"
                                            aria-describedby="basic-addon1"
                                            value={this.state.userEmailSubject}
                                            onChange={e => this.setState({userEmailSubject: e.target.value})}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <FormControl 
                                            className="message-input" 
                                            placeholder="Message"
                                            as="textarea" 
                                            rows={6}
                                            value={this.state.userEmailMessage}
                                            onChange={e => this.setState({userEmailMessage: e.target.value})}
                                        />
                                    </Form.Group>
                                    <Button 
                                        variant="primary"
                                        size="lg"
                                        className="button" 
                                        onClick={this.submitButtonClicked}
                                        >SUBMIT</Button>
                                </Form>
                        </div>
                    </div>
                </Row>
                <div style={{ height: '2vh' }}></div>
                

                <div style={{ height: '2vh' }}></div>
                <Row className="justify-content-center">
                    <div className="bottom-on-day-capture-label">On Day Capture</div>
                </Row>
                <div style={{ height: '1vh' }}></div>

            </div>
            
        )
    }
}

export default ContactUsMobile;