import React, { useState } from 'react';
import './VideoCarouselMobile.css';

const videos = [
  {
    id: 1,
    src: process.env.PUBLIC_URL + '/videos/angledScreamerGoal.mp4',
    title: 'Angled Screamer',
    poster: process.env.PUBLIC_URL + '/videos/firstFrame/angledScreamerGoalFirstFrame.jpg',
  },
  {
    id: 2,
    src: process.env.PUBLIC_URL + '/videos/greatPlayGoal.mp4',
    title: 'Great Play',
    poster: process.env.PUBLIC_URL + '/videos/firstFrame/greatPlayGoalFirstFrame.jpg',
},
  {
    id: 3,
    src: process.env.PUBLIC_URL + '/videos/girlsScreamerGoal.mp4',
    title: 'Girls Screamer',
    poster: process.env.PUBLIC_URL + '/videos/firstFrame/girlsScreamerGoalFirstFrame.jpg',
  },
  {
    id: 4,
    src: process.env.PUBLIC_URL + '/videos/sombreroGoal.mp4',
    title: 'Sombrero Goal',
    poster: process.env.PUBLIC_URL + '/videos/firstFrame/sombreroGoalFirstFrame.jpg',
  },
  {
    id: 5,
    src: process.env.PUBLIC_URL + '/videos/dogBlocksGoal.mp4',
    title: 'Dog Block',
    poster: process.env.PUBLIC_URL + '/videos/firstFrame/dogBlocksGoalFirstFrame.jpg',
  },
  {
    id: 6,
    src: process.env.PUBLIC_URL + '/videos/blooperReelGoal.mp4',
    title: 'Blooper Reel',
    poster: process.env.PUBLIC_URL + '/videos/firstFrame/blooperReelGoalFirstFrame.jpg',
  }
];

const VideoCarouselMobile = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const handleNextVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  const handlePrevVideo = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex - 1 + videos.length) % videos.length);
  };

  return (
    <div className="video-carousel-mobile-container">
      <video
        key={videos[currentVideoIndex].id}
        className="video"
        src={videos[currentVideoIndex].src}
        controls
        // autoPlay
        poster={videos[currentVideoIndex].poster} // Add poster attribute
      />
      <div className="nav-arrows">
        <button onClick={handlePrevVideo} disabled={currentVideoIndex === 0}><i className="fas fa-chevron-left"></i></button>
        <button onClick={handleNextVideo} disabled={currentVideoIndex === videos.length - 1}><i className="fas fa-chevron-right"></i></button>
      </div>
    </div>
  );
};

export default VideoCarouselMobile;
