import firebase from 'firebase/app' // doing import firebase from 'firebase' or import * as firebase from firebase is not good practice.
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore';
import Axios from 'axios';
// commented out from firebase page
import { initializeApp } from "firebase/app";

//https://betterprogramming.pub/a-simple-and-easy-contact-form-step-by-step-tutorial-react-js-1532bc025980
//this is behind a paywall but we can use the custom extension https://github.com/fferrin/free-medium to view it
//you dont have to do the db stuff
//on the 'Enable Less Secure App Access on Gmail' section, that's no longer doable on google, so do this
// https://www.youtube.com/watch?v=zwYaNJFknEw (use app passwords)

// Initialize Firebase
const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };


// commented out from firebase page
const app = initializeApp(config);

export { Axios }