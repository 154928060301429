import React from 'react';
import Header from './Header';
import Services from './Services';
import CameraUsage from './CameraUsage';
import ContactUsResponsive from './ContactUsResponsive';
import './Layout.css'; // Import CSS file for styling

function Layout() {
  return (
    <div className="layout">
      <Header />
      <Services />
      <CameraUsage />
      <ContactUsResponsive />
    </div>
  );
}

export default Layout;
