import React from 'react';
import './HighlightVideo.css';

const HighlightVideo = () => {
  return (
    <div className="video-player-container">
      <video
        className="video-player"
        src="https://cdn.prod.website-files.com/65a1660e61e47f70a246ac5c/65fdf284a0b6a47acb75f44f_Veo%20Editor%20-%201%20Highlights%20and%20events-transcode.mp4"
        controls
        poster="https://your-poster-image-url.jpg" // Provide a suitable poster image URL
      />
    </div>
  );
};

export default HighlightVideo;
