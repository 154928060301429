import React, { useState, useEffect } from 'react';
import './Header.css'; // Import CSS file for styling
import logo from './logo.svg';


function Header() {
  const [animationComplete, setAnimationComplete] = useState(false);

  useEffect(() => {
    // Set a timeout to mark the animation as complete after 1 second
    const timeoutId = setTimeout(() => {
      setAnimationComplete(true);
    }, 1000);

    // Clear the timeout when the component unmounts to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []); // Empty dependency array ensures this effect runs only once on component mount

  return (
    <header className={`header ${animationComplete ? 'animation-complete' : ''}`}>
    <div className="logo-container">
    {/* <img src="https://cdn-icons-png.flaticon.com/512/59/59400.png" alt="Camera Icon" className={`logo ${animationComplete ? 'fade-out' : ''}`} /> */}
      <img src={`${process.env.PUBLIC_URL}/logoSvgs/finalLogoCropped.svg`} alt="Camera Icon" className={`logo ${animationComplete ? 'fade-out' : ''}`} />
      <div className={`text-overlay ${animationComplete ? 'fade-in' : ''}`}>
          <span className="first-line">On Day</span>
          <hr className="line" />
          <span className="second-line">Capture</span>
        </div>
    </div>
  </header>
  );
}

export default Header;
